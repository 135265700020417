<template>
  <div>
    <div class="backClass"><a-button type="primary" @click="handleCancel"> 返回 </a-button></div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :labelCol="{ style: 'width: 100px' }"
      :wrapper-col="{ span: 8 }"
      :rules="rules"
    >
      <a-form-model-item label="弹窗类型" prop="typeId">
        <a-select
          v-model="form.typeId"
          placeholder="请选择"
          disabled
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in selectList" :key="option.typeId" :value="option.typeId">
            {{ option.typeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="弹窗名称" prop="popupName">
        <a-input disabled placeholder="请输入弹窗名称" :max-length="20" v-model.trim="form.popupName" />
      </a-form-model-item>
      <a-form-model-item label="优先级" prop="priority">
        <a-input
          type="Number"
          oninput="if(value.length>5)value=value.slice(0,5)"
          max="99999"
          :disabled="disableType == 1"
          placeholder="请输入优先级序号"
          v-model.trim="form.priority"
        />
        <div class="upload_tip">请注意，弹窗优先级不可重复</div>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="popupStatus">
        <a-select
          v-model="form.popupStatus"
          placeholder="请选择"
          :disabled="disableType == 1"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in statusList" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 10 }">
        <a-button v-show="disableType != 1" type="primary" :loading="onSubmitLoading" @click="handleOk">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import * as api from "@/api/popup";
import moment from "moment";
export default {
  data() {
    let checkPriority = (rule, value, callback) => {
      // 校验优先级
      if (Number(value) >= 0) {
        return callback();
      }
      // 不合法
      callback(new Error("格式不正确请重新输入"));
    };
    return {
      onSubmitLoading: false, //提交loading开关
      statusList: [
        //状态
        { label: "禁用", value: 1 },
        { label: "启用", value: 0 },
      ],
      id: "", //详情id
      disableType: "", //编辑状态
      selectList: [], // 弹窗类型下拉值
      form: {
        popupName: "", //弹窗名称
        typeId: undefined, //弹窗类型
        priority: "", //优先级
        popupStatus: undefined, //状态
      },
      rules: {
        popupName: [{ required: true, message: "请输入弹窗名称", trigger: "blur" }],
        popupStatus: [{ required: true, message: "请选择状态", trigger: "change" }],
        priority: [
          { required: true, message: "请输入优先级", trigger: "blur" },
          { validator: checkPriority, trigger: "blur" },
        ],
        typeId: [{ required: true, message: "请选择弹窗类型", trigger: "change" }],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.disableType = this.$route.query.type;
    this.getSelect();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    moment,
    //校验优先级
    async priorityChange() {
      if (this.form.priority) {
        const res = await api.checkpriority({ priority: this.form.priority, popupId: this.$route.query.id });
        if (!res.data) {
          this.form.priority = "";
          this.$message.error("优先级重复!");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    //获取详情
    async getDetail() {
      const res = await api.getpopupbypopupid({ popupId: this.$route.query.id });
      if (res.code === "200") {
        this.form = res.data;
      }
    },
    //获取弹窗类型
    async getSelect() {
      const res = await api.listall();
      if (res.code === "200") {
        this.selectList = res.data;
      }
    },
    //提交事件
    async handleOk() {
      // 规则校验
      let priorityType = await this.priorityChange();
      if (priorityType) {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            this.onSubmitLoading = true;
            let obj = {
              popupId: this.form.popupId,
              popupName: this.form.popupName,
              popupStatus: this.form.popupStatus,
              typeId: this.form.typeId,
              priority: Number(this.form.priority),
            };
            const res = await api.popupupdatesys(obj);
            this.onSubmitLoading = false;
            if (res.code === "200") {
              this.$message.success("编辑成功!");
              this.$router.go(-1);
            }
          } else {
          }
        });
      }
    },
    //返回事件
    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.ant-form-item {
  display: flex;
}
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
.up_file {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backClass {
  display: flex;
  justify-content: flex-end;
}
</style>