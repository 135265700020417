import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"



//弹窗列表
export function popuplist(data) {
    return fetchApi(URL.POPUPLIST+'?pageNum='+data.pageNum+'&pageSize='+data.pageSize,data, "post", "data")
}
//添加弹窗
export function popupinsert(data) {
    return fetchApi(URL.POPUPINSERT, data, "post", "data")
}
//查看弹窗信息
export function getpopupbypopupid(params) {
    return fetchApi(URL.GETPOPUPBYPOPUPID, params, "get")
}
//编辑非系统弹窗
export function popupupdate(data) {
    return fetchApi(URL.POPUPUPDATE, data, "post", "data")
}
//编辑系统弹窗
export function popupupdatesys(data) {
    return fetchApi(URL.POPUPUPDATESYS, data, "post", "data")
}
// 启用弹框
export function enablepopupbypopupid(params) {
    return fetchApi(URL.ENABLEPOPUPBYPOPUPID, params, "get")
}
//禁用弹框
export function disablepopupbypopupid(params) {
    return fetchApi(URL.DISABLEPOPUPBYPOPUPID, params, 'get')
}
// 检查优先级是否重复
export function checkpriority(params) {
    return fetchApi(URL.CHECKPRIORITY, params, "get")
}
// 删除弹框
export function deletepopupbypopupid(params) {
    return fetchApi(URL.DELETEPOPUPBYPOPUPID, params, "get")
}
//获取非系统的弹框类型列表
export function popuptypelist(data) {
    return fetchApi(URL.POPUPTYPELIST, data, "post", "data")
}
//获取非系统的弹框类型列表
export function getSelectList(data) {
    return fetchApi(URL.GETSELECTLIST, data, "get")
}
//获取所有的弹框类型
export function listall(data) {
    return fetchApi(URL.LISTALL, data, "get")
}
//重新上传用户数据
export function uploadagain(data) {
    return fetchApi(URL.UPLOADAGAIN, data, 'post', "data")
}
//批量删除上传的用户数据
export function deletebatch(data) {
    return fetchApi(URL.DELETEBATCH, data, 'post', "data")
}
//分页查询用户的数据
export function uploadexcellist(params) {
    return fetchApi(URL.UPLOADEXCELLIST, params, 'get')
}

// 根据活动id查询项目列表
export const getProjectListByActivityId = (id) => {
    const params = {
        activityId: id
    }
    return fetchApi(URL.GET_PROJECT_LIST_BY_ACTIVITY_ID,params,'get')
}

/**
 *  所有可配置活动
 */
export const selectAllActivity = () => {
    return fetchApi(URL.SELECT_ALL_ACTIVITY)
}